import { datadogLogs } from "@datadog/browser-logs";
import { TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Axios from "axios";
import React, { useState } from "react";
import XFormData from "../../Utilities/XFormData";
import treesourceLogo from "../Loaders/graphic-test3-01.svg";

const PREFIX = "ForgottenPassword";

const classes = {
  formControl: `${PREFIX}-formControl`,
  input: `${PREFIX}-input`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.input}`]: {
    margin: 10,
  },
}));

const ForgottenPassword = () => {
  const [emailAddress, setEmail] = useState("");
  const [emailError, setEmailError] = useState<String>("");
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const generateLink = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-store",
      },
    };

    try {
      datadogLogs.logger.info(
        `Reset Password request done by ${emailAddress}`,
        {
          email: emailAddress,
        }
      );

      const data = new XFormData().format({
        email: emailAddress,
      });

      await Axios.post(`/api/user/resetpassword`, data, config);
      setLoading(false);
    } catch (error: any) {
      datadogLogs.logger.error(`Reset Password Failed for ${emailAddress}`, {
        errorData: error?.response?.data,
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validForm()) {
      setSent(true);
      setLoading(true);
      generateLink();
    }
  };

  const validForm = () => {
    if (emailError.length === 0) {
      return true;
    }
    return false;
  };

  const validateInput = (inputName: String, textToValidate: String) => {
    switch (inputName) {
      case "Email":
        setEmailError(
          !textToValidate.includes("@")
            ? "Please input a valid email address"
            : ""
        );
        break;

      default:
        break;
    }
  };

  const renderFinished = () => {
    return <>
      <p>
        {`A password reset link has been sent to ${emailAddress}. Please check your email.`}
      </p>
    </>
  }

  const renderLoading = () => {
    return <>
      <br/>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <img style={{width: 200}} src={treesourceLogo} alt="treesource loading spinner"/>
        <p>Sending your request to our servers!</p>
      </div>
    </>
  }

  const renderInitial = () => {
    return <>
      <p>
        Enter your email below and we'll send you a password reset link if
        you're registered on TreeSource.com.
      </p>
      <form
          onSubmit={handleSubmit}
          className={classes.formControl}
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "300px",
          }}
      >
        <TextField
            className={classes.input}
            required
            variant="outlined"
            name="Email"
            label="Email"
            error={emailError ? true : false}
            helperText={emailError ? emailError : ""}
            onChange={(e) => {
              setEmail(e.target.value);
              validateInput(e.target.name, e.target.value);
            }}
            value={emailAddress}
        />
        <Button
            className={classes.input}
            type="submit"
            variant="contained"
            color="primary"
        >
          {" "}
          Request link to reset password
        </Button>
      </form>
    </>
  }

  const renderContent = () => {
    if(!sent) {
      return renderInitial();
    } else if (loading) {
      return renderLoading();
    } else {
      return renderFinished();
    }
  }

  return (
    <Root>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 200,
        }}
      >
        <h1>Reset Password Request</h1>
        {renderContent()}
      </div>
    </Root>
  );
};

export default ForgottenPassword;
